
import React from 'react';
import Element from '../element'
import { connect } from 'react-redux';
import { analyticsClickEvent } from '../../../action/firebaseAnalytics/firebaseAnalytics';
import { GTMFieldErrorChange, GTMFieldValidChange } from '../../../utils/GtmDatalayer';
import Cookies from 'universal-cookie';
import { PropTypes } from 'prop-types'; 
import { frontEndLogs } from '../../../action/logs/frontEndLogs';

function checkNextpropsThreeandOne(nextProps,prevState){
  if (nextProps.priority === 3) {
       
    if (prevState.showDev[prevState.fieldname] === false) {
      let showDev = prevState.showDev;
      showDev[prevState.fieldname] = true;
        return {
         showDev
        };
      }
    }
if (nextProps.priority === 1) {
  if (prevState.showDev[prevState.fieldname] === true) {
    let showDev = prevState.showDev;
    showDev[prevState.fieldname] = false;
    return {
      showDev,
      value: ''
    };
  }
}
}

function checkNextPropsThreeandfrequency(nextProps,prevState){
  if (nextProps.priority === 3 && nextProps.amountRequested !== '') {
    if (prevState.showDev[prevState.fieldname] === true) {
      let showDev = prevState.showDev;
      showDev[prevState.fieldname] = false;
      return {
        showDev,
        value: ''
      };
    }
    
  }
  if (nextProps.priority === 1 && prevState.fieldname === 'frequency') {
    let showDev = prevState.showDev;
    showDev[prevState.fieldname] = true;
      return {
        showDev,
        value: ''
      };
   
  }
}

function checkApplicationFieldvalidationInProps(nextProps,prevState){
  if (nextProps.amountRequested.split(',').join('') > nextProps.threashold) {
    let resultCheckedPropsThreeandOne = checkNextpropsThreeandOne(nextProps,prevState);
    if(resultCheckedPropsThreeandOne){
      return resultCheckedPropsThreeandOne;
    }
  } else {
    let resultCheckedPropsThreeandfrequency = checkNextPropsThreeandfrequency(nextProps,prevState);
    if(resultCheckedPropsThreeandfrequency){
      return resultCheckedPropsThreeandfrequency;
    }
  }
}
export class Select extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: '',
      question: '',
      errors: {}, stages: {}, input: {}, showDev: {},
      fieldname: this.props.field_api_name,
      showValidation: this.props.showAppValidations,
      frequency_options: {},
      showValidationForResidentialStatus: false,
      isDropdownOpen: false,
      isDropdownOpenForSelect: false,
      isMortageRentFieldEnabled: false
    }
    this.handleSearch = this.handleSearch.bind(this);
  }

  componentDidMount() {
    let questionValues={};
    questionValues['field_api_name']=this.props.field_api_name;
    questionValues['priority']=this.props.priority;
    questionValues['stage']=this.props.stage;
    questionValues['field_question'] = this.props.field_label;
    if(this.props.field_api_validations.length!=0){
      questionValues['validation']='yes';
    }else{
      questionValues['validation']='no';
    }
    this.props.questionSave(questionValues);
    if (this.props.priority === 3) {
      let showDev = this.state.showDev;
      showDev[this.props.field_api_name] = false;
      let stages = {};
      stages[this.props.field_api_name] = '';
      stages['stage'] = this.props.stage;
      this.props.appvalidations(stages);
      this.setState({
        showDev
      });
    } else {
      let showDev = this.state.showDev;
      showDev[this.props.field_api_name] = true;
      this.setState({
        showDev
      });
    }

    this.props.getEmploynmentstatus(false);
    this.setState({frequency_options: this.props.getApplicationQuestions})    
    setTimeout(() => {
      const cookie = new Cookies();
      let searchParams = new URLSearchParams(window.location.search);
      let redirectToken = searchParams.get('redirectToken'); 
      let approvedUrl = searchParams.get("approvedURL");
      const decodedApprovedUrl = decodeURIComponent(approvedUrl);
      const approvedParams = new URLSearchParams(decodedApprovedUrl.split('?')[1]);
      let purchaseTrackingId = approvedParams.get("trackingId");

      if (process.env.REACT_APP_ENCRYPTED_PURCHASE_URL === 'true' && redirectToken) {
        purchaseTrackingId = cookie.get('trackingId')
      }
      if(purchaseTrackingId && cookie.get('term') !== '' && cookie.get('term') !== undefined && cookie.get('loanAmount') !== '' && cookie.get('loanAmount') !== undefined){

       let termPrepopulateStatus =  this.handlingTermsShowInPurchaseLink(cookie.get('loanAmount'),cookie.get('term'));
      let input = {};
      input['Loan_Term_Requested__c'] = cookie.get('term');
      this.props.saveApplicationDatas(input);
      if(!termPrepopulateStatus){
        if(this.props.field_api_name === 'Loan_Term_Requested__c'){
        if (this.props.field_api_validations !== '') {
          frontEndLogs('Loan Term field validation', false, 200, 'componentDidMount',this.props.field_api_validations);
          this.props.field_api_validations.map((src) => {
            if (src.flxType__c === 'Contains') {
              let stagesValues={}, errorValues={};
              errorValues['Loan_Term_Requested__c'] = src.flxError_Message__c;
          stagesValues['Loan_Term_Requested__c'] = false;
          this.props.appvalidations(errorValues)
        this.props.secondSectionValidationUpdate(stagesValues);
              let errors = this.state.errors;
              errors[this.props.field_api_name] = src.flxError_Message__c;
        this.setState({
          errors
        });
        return false;
            }
          })
        }
      }
        return false;
      }
      }

      if(!purchaseTrackingId && localStorage.getItem('quote-loan-term')){
        let input = {};
        input['Loan_Term_Requested__c'] = localStorage.getItem('quote-loan-term');
        this.props.saveApplicationDatas(input);
  
        }
    }, 200);
  }

  handlingTermsShowInPurchaseLink= (loanAmount,loanTerms) => {
    let termPrepopulateStatus = false;
    let allRetailerTerms = this.state.frequency_options?.data[0]['serviceability_details'];
   allRetailerTerms.forEach(data => {      
      if(data.min_loan_amount <= loanAmount && data.max_loan_amount >= loanAmount && data.term === loanTerms){
        termPrepopulateStatus = true;
      }
    })
    return termPrepopulateStatus;
    
  }

  // For hide select dropdown when outside click
  handleDivBlur = (field, fieldLabel) => {
    const saveApplicationData = Object.assign({}, ...this.props.saveApplicationData);
    frontEndLogs('select field handle blur events', false, 200, 'handleDivBlur', saveApplicationData);
    this.GTMhandleSelectChange(field, fieldLabel);
    if(this.state.isDropdownOpen){
    this.setState({isDropdownOpen: false},()=>{
      console.log('handleDivBlur-Loan_Term_Requested__c',this.state.isDropdownOpen);
    }) 
  }
  
    if(this.state.isDropdownOpenForSelect){
      this.setState({isDropdownOpenForSelect: false},()=>{
        console.log('handleDivBlur-other-option',this.state.isDropdownOpenForSelect);
      }) 
    }
  };

  GTMhandleSelectChange = (field, fieldLabel) => {
    const { applicationValidation } = this.props;
    if (applicationValidation.some(value => value.hasOwnProperty(field))) {
      const latestEntry = [...applicationValidation].reverse().find(value => value.hasOwnProperty(field));
      if (latestEntry && latestEntry[field] !== "") {
        GTMFieldErrorChange(fieldLabel);
      } else {
        GTMFieldValidChange(fieldLabel);
      }
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if(nextProps.isMismatchedLoanTerm){
      const inputLoanTermElement = document.getElementById('Loan_Term_Requested__c');
      inputLoanTermElement.focus();
    }

    if (nextProps.showAppValidations !== prevState.showValidation) {
      if (nextProps.showAppValidations !== '' || nextProps.stage === '2') {
        return {
          showValidation: nextProps.showAppValidations
        };
      }
    
    }

    if(nextProps.saveApplicationData['Current_Residential_Status__c'] && !prevState.showValidationForResidentialStatus) {
      return {
        showValidationForResidentialStatus: true
      }
    }


    if (nextProps.amountRequested.split(',').join('').length > 1) {
      let resultCheckFieldProps = checkApplicationFieldvalidationInProps(nextProps,prevState);
      if(resultCheckFieldProps){
        return resultCheckFieldProps;
      }
    }
  }

  checkCookieTermDetails = (cookie,input,value) => {
    if(cookie.get('term') !== '' && cookie.get('term') !== undefined && cookie.get('loanAmount') !== '' && cookie.get('loanAmount') !== undefined){
      if(this.state.input['frequency'] !== '' && this.state.input['frequency'] !== undefined) {
        frontEndLogs('Term pre-populate when come by purchase link', false, 200, 'checkCookieTermDetails','loanAmount:'+cookie.get('loanAmount')+',Terms :'+cookie.get('term'));
        input['Loan_Term_Requested__c'] = cookie.get('term');
        this.props.saveApplicationDatas(input);

        let stages = {}
        if (this.props.saveApplicationData['Loan_Term_Requested__c'] !== '') {
          stages['Loan_Term_Requested__c'] = '';
          stages['stage'] = this.props.stage;
          this.props.appvalidations(stages);
        }
      }
    }
    
    this.setState({ question: '' })
    this.props.field_options.map((src) => {
      if (src.flxActualValue__c === value) {
        this.setState({ question: src.Questions })
      }
    }
    )
  }

  checkResidentialValidation = (event,saveApplicationData) => {
    if (this.props.field_api_name === 'Current_Residential_Status__c') {
      if(event.target.value==='Homeowner - mortgaged' ||  event.target.value==='Tenant/Boarding'){
        if(saveApplicationData['Fractional_Residential_Expense__c'] !== undefined && saveApplicationData['Fractional_Residential_Expense__c'].charAt(0)==0){
          let fractionalValues={};
          fractionalValues['Fractional_Residential_Expense__c']='';
          this.props.saveApplicationDatas(fractionalValues);
        }
      
      }else{
          let inputValuesExpenseC = {}
          inputValuesExpenseC['Fractional_Residential_Expense__c'] = '';
          this.props.saveApplicationDatas(inputValuesExpenseC)
        }
    }
  }

  checkEmploymentStabilityValidation = (event,ValidationFields,saveApplicationData) => {
    if (this.props.field_api_name === 'Employment_Stability__c') {
      if (event.target.value === 'Unemployed' || event.target.value === 'Retired' || event.target.value === 'Student' || event.target.value === '') {
        this.props.getEmploynmentstatus(false)
        
        let stagesValues={}
        stagesValues['occupation'] = false;
        this.props.fourSectionValidationUpdate(stagesValues);

        let occupationStagesValues={}
        occupationStagesValues['occupation'] = '';
        this.props.saveApplicationDatas(occupationStagesValues);

        ValidationFields.forEach((src) => {
          let stages = {}
          let inputValues = {}
          inputValues[src] = '';
          stages[src] = '';
          stages['stage'] = this.props.stage;
          this.props.appvalidations(stages);
          this.props.saveApplicationDatas(inputValues);
        })
      } else {
        ValidationFields.forEach((src) => {
          let stages = {}
          if (saveApplicationData[src] === '') {
            stages[src] = 'This is Required';
            stages['stage'] = this.props.stage;
            this.props.appvalidations(stages);
          }
        })
        this.props.getEmploynmentstatus(true)
      }

    this.validationCheckOfProgressBarForEmploymentStatus(event.target.value);
    }
  }

  handleSearch = (event) => {
    const cookie = new Cookies();

    this.setState({ showValidation: 'yes' });
    let input = this.state.input;
    let value = event.target.value;
    analyticsClickEvent('picklist',value);
    input[event.target.name] = event.target.value;
    this.props.saveApplicationDatas(input);
    this.setState({
      input
    });

      this.checkCookieTermDetails(cookie,input,value);
    const saveApplicationData = Object.assign({}, ...this.props.saveApplicationData);
    console.log(saveApplicationData);
    let ValidationFields = ['occupation', 'time_in_occupation'];
       //Employment mortage value should not allowed to zero and rent Homeowner - mortgaged and Tenant/Boarding start
       this.checkResidentialValidation(event,saveApplicationData);

    console.log(saveApplicationData);
    this.checkEmploymentStabilityValidation(event,ValidationFields,saveApplicationData);
    this.handleValidations();
    setTimeout(() => {
      for(const [i,value] of this.props.applicationValidation.entries()){
        if(value.hasOwnProperty(this.props.field_api_name)){
          let checkState = value[this.state.eventName] !== undefined && value[this.props.field_api_name] !== "";
          if(checkState){
            console.log('--> ', i);
            GTMFieldErrorChange(this.props.field_label);
            return false;
          }
          GTMFieldValidChange(this.props.field_label);
          return false;
        }
     } 
    }, 300);
  }

  validationCheckOfProgressBarForEmploymentStatus = (eventValue) => {
    
    let stagesValues={}
    if(eventValue !== '') {
        stagesValues['Employment_Stability__c'] = true;
        this.props.fourSectionValidationUpdate(stagesValues);
    }
    else {
      stagesValues['Employment_Stability__c'] = false;
        this.props.fourSectionValidationUpdate(stagesValues);
    }      
  }

  handleValidations = () => {
    let errors = this.state.errors;
    let stages = {};
    errors[this.props.field_api_name] = '';
    stages[this.props.field_api_name] = '';
    stages['stage'] = this.props.stage;
    this.props.appvalidations(stages);
    this.setState({
      errors
    });

    setTimeout(() => {
      if(this.props.field_api_name === 'Loan_Term_Requested__c') {
        const allInputFieldsData = Object.assign({}, ...this.props.applicationValidation);

        let stagesValues={}
        if(allInputFieldsData['Loan_Term_Requested__c'] == '') {
          stagesValues['Loan_Term_Requested__c'] = true;
        }
        else {
          stagesValues['Loan_Term_Requested__c'] = false;
        }
        this.props.secondSectionValidationUpdate(stagesValues);

      }
    }, 10);
    if (this.state.input[this.props.field_api_name] === undefined || this.state.input[this.props.field_api_name] === '') {
      this.props.field_api_validations.map((src) => {
        errors[this.props.field_api_name] = src.flxError_Message__c;
        stages[this.props.field_api_name] = src.flxError_Message__c;
        stages['stage'] = this.props.stage;
        this.props.appvalidations(stages);
        this.setState({
          errors
        });

        return false;
      })
    }

    setTimeout(() => {
      let stagesValues={}
      if(this.state.showValidationForResidentialStatus && this.state.input['Current_Residential_Status__c'] !== '') {
        stagesValues['Current_Residential_Status__c'] = true;
        this.props.thirdSectionValidationUpdate(stagesValues);
      }
      else if(this.props.field_api_name == 'Current_Residential_Status__c') {
        stagesValues['Current_Residential_Status__c'] = false;
        this.props.thirdSectionValidationUpdate(stagesValues);
      }
    }, 600);

    if (this.state.input['Current_Residential_Status__c'] !== '' && this.props.field_api_name == 'Current_Residential_Status__c') {
      errors['Current_Residential_Status__c'] = '';
      stages['Current_Residential_Status__c'] = '';
      stages['stage'] = this.props.stage;
      this.props.appvalidations(stages);
      this.setState({
        errors, showValidationForResidentialStatus: true
      });
      return false
    }

    
    if(this.state.input['Current_Residential_Status__c'] == ''){
      this.setState({showValidationForResidentialStatus: false})
      return false;
    }

  }

  // Function to get the frequency and terms for the entered amount within range of mininum and maximum loan amounts
  selectedAmountInterest=()=>{
    let temporaryRate = this.state.frequency_options.data[0].serviceability_details
    let finalArr = []
    let match = false

    temporaryRate.map((term)=>{
      let term_obj = {}
      let termArr = []

      // Have added condition in the if condition for missing terms when the Loan Amount was higher
      temporaryRate.map((compareEachTerm)=>{
          if(term['min_loan_amount'] == compareEachTerm['min_loan_amount'] 
          && term['max_loan_amount'] == compareEachTerm['max_loan_amount']
           && term['frequency'] === compareEachTerm['frequency'] && this.props.amountRequested  >= Number(compareEachTerm['min_loan_amount']) && this.props.amountRequested  <= Number(compareEachTerm['max_loan_amount'])){
              termArr.push(compareEachTerm['term'])
          }
      })
      if(finalArr.length){
          finalArr.forEach((res)=>{
            // Have added condition in the if condition for missing terms when the Loan Amount was higher
              if(res['min_loan_amount'] === term['min_loan_amount'] 
              && res['max_loan_amount'] === term['max_loan_amount']
               && term['frequency'] === res['frequency']){
                  match = true    
              }
          })
      }
      term_obj.min_loan_amount = term['min_loan_amount']
      term_obj.max_loan_amount = term['max_loan_amount']
      term_obj.frequency = term['frequency']
      term_obj.term = termArr
      if(!match && termArr.length){
      finalArr.push(term_obj)
      }
      match = false
    });

    return finalArr
  }


  validateTerm = () => {
    if(this.props.field_api_name === 'Loan_Term_Requested__c') {
      setTimeout(() => {
        const allInputFieldsData = Object.assign({}, ...this.props.saveApplicationData);

        let stagesValues={}, errorValues={};
        if(allInputFieldsData['Loan_Term_Requested__c'] !== '') {
          errorValues['Loan_Term_Requested__c'] = ''
          stagesValues['Loan_Term_Requested__c'] = true;

        }
        else {
          errorValues['Loan_Term_Requested__c'] = 'This is Required';
          stagesValues['Loan_Term_Requested__c'] = false;

        }

        this.props.appvalidations(errorValues)
        this.props.secondSectionValidationUpdate(stagesValues);
      }, 400);

    }
  }

  validateFractionalResidentialExpenses = () => {
      let stagesValues={}, errorValues={};
      if (this.state.isMortageRentFieldEnabled){
        errorValues['Fractional_Residential_Expense__c'] = ''
        stagesValues['Fractional_Residential_Expense__c'] = true;
        this.props.appvalidations(errorValues)
        this.props.fourSectionValidationUpdate(stagesValues);
      }
  }

  requiredFractionalResidentialExpenses = (allInputFieldsData) => {
      let stagesValues={}, errorValues={};
      let fractionalResidentialExpenses = allInputFieldsData['Fractional_Residential_Expense__c'];
      if (fractionalResidentialExpenses === ''){
         errorValues['Fractional_Residential_Expense__c'] = 'This is Required';
         stagesValues['Fractional_Residential_Expense__c'] = false;
  
      } else if (fractionalResidentialExpenses !== undefined && (/^0+$/.test(fractionalResidentialExpenses))) {
         errorValues['Fractional_Residential_Expense__c'] = 'Amount cannot be £0 if your Residential status is Homeowner - mortgaged or Tenant/Boarding';
         stagesValues['Fractional_Residential_Expense__c'] = false;
      }
      this.props.appvalidations(errorValues)
      this.props.fourSectionValidationUpdate(stagesValues);
  }

  getEnabledStateForMortageRentField = (allInputFieldsData) => {
    let fractionalResidentialExpenses = allInputFieldsData['Fractional_Residential_Expense__c'];
    if (fractionalResidentialExpenses !== '' ){
      this.setState({ isMortageRentFieldEnabled: true });
    }
  }

  getSelectLabel(){
    return (
      <div className='d-flex flex-row'>
        <span>{this.props.field_label ? this.props.field_label : ''}
        {(this.props.flxQuestionMoreInfo) &&
          <a className='fieldTip'>
            <span>
                <img src="images/humm-infoIcon.svg" alt="humm-logo" />
                {this.props.flxQuestionMoreInfo}
            </span>
            <img src="images/more-info-icon.svg" alt="info-icon" />
          </a>
        }
        </span>
      </div>
    );
  }


  selectedAmountAndTerms = () => {
    const temporaryRate = this.state.frequency_options.data[0].serviceability_details;
    const finalArr = [];

    temporaryRate.forEach((term) => {
        let existingEntry = finalArr.find((entry) => (
            entry.min_loan_amount === term.min_loan_amount &&
            entry.max_loan_amount === term.max_loan_amount
        ));

        if (existingEntry) {
            existingEntry.term.push(term.term);
        } else {
            finalArr.push({
                min_loan_amount: term.min_loan_amount,
                max_loan_amount: term.max_loan_amount,
                term: [term.term]
            });
        }
    });

    return finalArr;

  }

  validateFieldValue = (fieldApiName) => {
    let stagesValues={}, errorValues = {};
    
    setTimeout(() => {
      let allInputFieldsData = Object.assign({}, ...this.props.saveApplicationData);
      let ValidationFields = ['occupation', 'time_in_occupation'];
      if(fieldApiName === 'Current_Residential_Status__c'){
        if(allInputFieldsData['Current_Residential_Status__c'] !== '') {
          this.getEnabledStateForMortageRentField(allInputFieldsData);
          if(allInputFieldsData['Current_Residential_Status__c'] === 'Homeowner - no mortgage' || allInputFieldsData['Current_Residential_Status__c'] === 'Living with parents/family') {
            this.validateFractionalResidentialExpenses();
           } else if(allInputFieldsData['Current_Residential_Status__c'] === 'Homeowner - mortgaged' || allInputFieldsData['Current_Residential_Status__c'] === 'Tenant/Boarding') {
             this.requiredFractionalResidentialExpenses(allInputFieldsData);
           }
          stagesValues['Current_Residential_Status__c'] = true;
          errorValues['Current_Residential_Status__c'] = '';

          this.setState({ showValidationForResidentialStatus: true })
          this.props.thirdSectionValidationUpdate(stagesValues);
          this.validatePurchaselinkAddressInPrepopulateInThirdSection();
        }
        else if(this.props.field_api_name == 'Current_Residential_Status__c') {
          stagesValues['Current_Residential_Status__c'] = false;
          errorValues['Current_Residential_Status__c'] = 'This is Required';

          this.setState({ showValidationForResidentialStatus: false })
          this.props.thirdSectionValidationUpdate(stagesValues);
        }

        this.props.appvalidations(errorValues);
      }
      else if(fieldApiName === 'Employment_Stability__c'){
        if(allInputFieldsData['Employment_Stability__c'] ) {
          this.handlebasedonEmploymentStability(allInputFieldsData,ValidationFields,stagesValues,errorValues);
        }
        else {
          stagesValues['Employment_Stability__c'] = false;
          errorValues['Employment_Stability__c'] = 'This is Required';

          this.props.getEmploynmentstatus(false)
          this.props.fourSectionValidationUpdate(stagesValues);
        }

        this.props.appvalidations(errorValues);
      }

    }, 600);


  }

  validatePurchaselinkAddressInPrepopulateInThirdSection = () => {
    const cookie = new Cookies();
    const allUserDetails = Object.assign({}, ...this.props.saveApplicationData);
    let fieldValues = {}, stageThreeValues = {}
    if (cookie.get('sourceURL')) {
      let cookiesValues = { DOB: cookie.get('Dob'), flatAbode: cookie.get('flatAbode'), houseandBuildingName: cookie.get('houseandBuildingName'), houseandBuildingNumber: cookie.get('houseandBuildingNumber'), street: cookie.get('numberAndStreet'), postcode: cookie.get('postcode'), townOrCity: cookie.get('townOrCity'), addressLine2: cookie.get('addressLine2') }
      frontEndLogs('Purchase link cookie params ', false, 200, 'validatePurchaselinkAddressInPrepopulateInThirdSection', cookiesValues);

      let addressStatus = this.handlevalidationaddressField(allUserDetails,fieldValues,stageThreeValues);
      console.log('validatePurchaselinkAddressInPrepopulateInThirdSection',addressStatus);
      fieldValues = addressStatus['fieldValues'];
      stageThreeValues = addressStatus['stageThreeValues'];
      if (allUserDetails['Current_Residential_Status__c']) {
        fieldValues['Current_Residential_Status__c'] = '';
        stageThreeValues['Current_Residential_Status__c'] = true;
      }
    }

    this.props.appvalidations(fieldValues);
    this.props.thirdSectionValidationUpdate(stageThreeValues);

  }

  handlevalidationaddressField = (allUserDetails, fieldValues, stageThreeValues) => {
    const cookie = new Cookies();
    if (cookie.get('numberAndStreet') !== '' && cookie.get('numberAndStreet') !== undefined) {
      if (allUserDetails['Address_Line1__c']) {
        fieldValues['Address_Line1__c'] = '';
        stageThreeValues['Address_Line1__c'] = true;
      }

    }
    if (cookie.get('postcode') !== '' && cookie.get('postcode') !== undefined) {
      if (allUserDetails['Postal_Code__c']) {
        fieldValues['Postal_Code__c'] = '';
        stageThreeValues['Postal_Code__c'] = true;
      }
    }

    if (cookie.get('townOrCity') !== '' && cookie.get('townOrCity') !== undefined) {
      if (allUserDetails['Town__c']) {
        fieldValues['Town__c'] = '';
        stageThreeValues['Town__c'] = true;
      }
    }
    return { fieldValues: fieldValues, stageThreeValues: stageThreeValues }
  }

  handlebasedonEmploymentStability = (allInputFieldsData,ValidationFields,stagesValues,errorValues) => {
    if(allInputFieldsData['Employment_Stability__c'] === 'Unemployed' || allInputFieldsData['Employment_Stability__c'] === 'Retired' || allInputFieldsData['Employment_Stability__c'] === 'Student'){
      this.props.getEmploynmentstatus(false)
      let stagesValues={}
      stagesValues['occupation'] = false;
      this.props.fourSectionValidationUpdate(stagesValues);
      ValidationFields.forEach((src) => {
        let stages = {}
        let inputValues = {}
        inputValues[src] = '';
        stages[src] = '';
        stages['stage'] = this.props.stage;
        this.props.appvalidations(stages);
        this.props.saveApplicationDatas(inputValues);
      })
    }
    else {
      this.props.getEmploynmentstatus(true)
      ValidationFields.forEach((src) => {
        let stages = {}
        if (allInputFieldsData[src] === '') {
          stages[src] = 'This is Required';
          stages['stage'] = this.props.stage;
          this.props.appvalidations(stages);
        }
      })
    }
    this.validationCheckOfProgressBarForEmploymentStatus(allInputFieldsData['Employment_Stability__c']);
    stagesValues['Employment_Stability__c'] = true;
    errorValues['Employment_Stability__c'] = '';

    this.props.fourSectionValidationUpdate(stagesValues);
  }

   //Function to display the options of frequency and terms in <option> tag for the entered amount
   SelectTermsDisplay=(isFrequencySelected)=>{
    const allUserData = Object.assign({}, ...this.props.saveApplicationData);
    let enteredAmount = isFrequencySelected ? this.selectedAmountInterest() : this.selectedAmountAndTerms();
    let resultFrequency = '';

      if(this.props.saveApplicationData !== null && this.props.saveApplicationData != undefined){
        Object.entries(this.props.saveApplicationData).forEach(([_, value]) => {
         
          Object.entries(value).forEach(([field, valueOfField]) => {
              if(field === "frequency" && valueOfField !== null) {
                  resultFrequency = valueOfField;

                  console.log(resultFrequency);
              }
          })
        });
      }


      let uniqueFrequencySet = new Set();
      /* Filtering the frequency from the enteredAmount by comparing frequency in the enteredAmount with frequency in the Set, if both frequencies matches don't add frequency in the filteredFrequency, otherwise add frequency to the
      filteredFrequency.
      */

      let filteredFrequency = enteredAmount.filter(item => !uniqueFrequencySet.has(item.frequency) && 
      uniqueFrequencySet.add(item.frequency))
      return this.setLoanTermDetailsBasedLoanAmount(isFrequencySelected,allUserData,enteredAmount,filteredFrequency);
  }




  setLoanTermDetailsBasedLoanAmount = (isFrequencySelected,allUserData,enteredAmount,filteredFrequency) => {
    if(!isFrequencySelected){
      setTimeout(() => {
        const allUserDetails = Object.assign({}, ...this.props.saveApplicationData);
        this.props.saveTermData(allUserDetails['Loan_Term_Requested__c']);
      }, 800);

      return enteredAmount.map((src) => {
          return src.term.map((eachTerm)=>{
              if(this.props.amountRequested >= src.min_loan_amount && 
                this.props.amountRequested <= src.max_loan_amount){
                return <div
                    className='Loan_Term_Requested__c'
                    key={eachTerm.toString()}
                    id={eachTerm}
                    onClick={(_e) => { 
                      let inputVal={}
                      inputVal['Loan_Term_Requested__c']=eachTerm
                      frontEndLogs('The user onClicked Term in the select box', false, 200, 'setLoanTermDetailsBasedLoanAmount','loanAmount:'+this.props.amountRequested+',Terms :'+eachTerm);
                      this.setState({isDropdownOpen: !this.state.isDropdownOpen})
                      this.props.saveApplicationDatas(inputVal)
                      this.validateTerm()
                    } }
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        // Trigger the same logic as the click handler when Enter key is pressed
                        let inputVal = {};
                        inputVal['Loan_Term_Requested__c'] = eachTerm;
                        frontEndLogs('The user onKeyDown Term in the select box', false, 200, 'setLoanTermDetailsBasedLoanAmount','loanAmount:'+this.props.amountRequested+',Terms :'+eachTerm);
                        this.setState({ isDropdownOpen: !this.state.isDropdownOpen });
                        this.props.saveApplicationDatas(inputVal);
                        this.validateTerm();
                      }
                    }}
                  >
                    {eachTerm}
                  </div>
                
              }
              return null;
          })
      })      
    }
  }

  // Function to display the options for other fields based on priority and threashold
  SelectOptionsDisplay=(fieldApiName)=>{
      
    return this.props.field_options.map((src) =>{

        if(src.priority===3 || src.priority===2 || src.priority===1 || src.priority===4){

        return <div
        className={fieldApiName}
        key={src.flxActualValue__c.toString()}
        onClick={(_e) => { 
          let inputVal={}
          inputVal[fieldApiName]=src.flxActualValue__c
          this.setState({isDropdownOpenForSelect: !this.state.isDropdownOpenForSelect})
          this.props.saveApplicationDatas(inputVal)
          this.validateFieldValue(fieldApiName)
        }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              let inputVal={}
              inputVal[fieldApiName]=src.flxActualValue__c
              this.setState({isDropdownOpenForSelect: !this.state.isDropdownOpenForSelect})
              this.props.saveApplicationDatas(inputVal)
              this.validateFieldValue(fieldApiName)
            }
          }}
      >
        {src.flxDisplayText__c}
      </div>
        }
    })
  }

  frequencySelectOptions = (disableValue,allDetailData,allDetails) => {
    return (      
      <React.Fragment>
        <div className="dynamic-input">
          <div className="form-group">
            {this.getSelectLabel()}
            
            <select id={this.props.field_api_name} className={`form-control ${disableValue}`} name={this.props.field_api_name} value={allDetailData[this.props.field_api_name]} onChange={(e) => {this.handleSearch(e);this.props.fieldChange(this.props.field_api_name, e.target.value, this.props.field_type,this.props.field_label, e)}}>
              <option value=''>Select</option>
              {this.SelectTermsDisplay(true)}                    
            </select>
            
            {allDetails[this.props.field_api_name] == "" && 
              <div className='input-group-append-text'>
                  <span>
                    <img src="images/tick-mark.svg" alt="tick icon for validation" className='validation-tickmark' />
                  </span>
              </div>
            }

            <p className="error-message">{allDetails[this.props.field_api_name] !== '' && this.state.showValidation === 'yes' &&
              <span className="app-error-message" style={{ color: 'Red' }}>{allDetails[this.props.field_api_name]}</span>
            }
            </p>
          </div>
        </div>

        <Element fieldItem={this.state.question} />
      </React.Fragment>

    );
}

lornTermSelectOptions = (disableValue,allDetailData,allDetails) => {
  return (      
    <React.Fragment>
      <div className="dynamic-input">
        <div className="form-group">
          {this.getSelectLabel()}


          <div className={`custom-select form-control ${disableValue}`} tabIndex={0} onBlur={() => { this.handleDivBlur(this.props.field_api_name,this.props.field_label)}}>
              <div className="selected-option" onClick={() => {
                this.setState({isDropdownOpen: !this.state.isDropdownOpen}) 
                }} onKeyDown={(event) => {
                  // Handle keyboard events here, for example, pressing Enter or Space
                  if (event.key === 'Enter') {
                    this.setState({ isDropdownOpen: !this.state.isDropdownOpen });
                  }
                }}>
                {allDetailData[this.props.field_api_name] || 'Select'}
                <div className='arrow-icon'>
                  <img src={this.state.isDropdownOpen ? "images/up-arrow-icon.png" : "images/down-arrow-icon.png" } alt="" />
                </div>
              </div>
              {this.state.isDropdownOpen && (
                <div className="options">
                  <div className='Loan_Term_Requested__c'
                    onClick={(_e) => { 
                      let inputVal={}
                      inputVal[this.props.field_api_name]=''
                      this.setState({isDropdownOpen: !this.state.isDropdownOpen})
                      this.props.saveApplicationDatas(inputVal)
                      this.validateTerm()
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        let inputVal={}
                        inputVal[this.props.field_api_name]=''
                        this.setState({isDropdownOpen: !this.state.isDropdownOpen})
                        this.props.saveApplicationDatas(inputVal)
                        this.validateTerm()
                      }
                    }}
                  >Select</div>
                  {this.SelectTermsDisplay(false)}
                </div>
              )}
            </div>


          {allDetails[this.props.field_api_name] == "" && 
            <div className='input-group-append-text'>
                <span>
                  <img src="images/tick-mark.svg" alt="tick icon for validation" className='validation-tickmark' />
                </span>
            </div>
          }

          { /* Reset loan term when frequency option is not selected*/}                                 
          <p className="error-message">{allDetails[this.props.field_api_name] !== '' &&
            <span className="app-error-message" style={{ color: 'Red' }}>{allDetails[this.props.field_api_name]}</span>
          }
          </p>
        </div>
      </div>

      <Element fieldItem={this.state.question} />
    </React.Fragment>

  );
}

otherSelectOptions = (disableValue,allDetailData,allDetails) => {
  return (      
    <React.Fragment>
      <div className="dynamic-input">
        <div className="form-group">
          {this.getSelectLabel()}

          <div className={`custom-select form-control ${disableValue}`} tabIndex={0} onBlur={() => { this.handleDivBlur(this.props.field_api_name,this.props.field_label)}}>
              <div className="selected-option" onClick={() => {
                this.setState({isDropdownOpenForSelect: !this.state.isDropdownOpenForSelect}) 
                }} onKeyDown={(event) => {
                  // Handle keyboard events here, for example, pressing Enter or Space
                  if (event.key === 'Enter') {
                    this.setState({isDropdownOpenForSelect: !this.state.isDropdownOpenForSelect})
                  }
                }}>
                {allDetailData[this.props.field_api_name] || 'Select'}
                <div className='arrow-icon'>
                  <img src={this.state.isDropdownOpenForSelect ? "images/up-arrow-icon.png" : "images/down-arrow-icon.png" } alt="" />
                </div>
              </div>
              {this.state.isDropdownOpenForSelect && (
                <div className="options">
                  <div className={this.props.field_api_name} 
                    onClick={(_e) => { 
                      let inputVal={}
                      inputVal[this.props.field_api_name]=''
                      this.setState({isDropdownOpenForSelect: !this.state.isDropdownOpenForSelect})
                      this.props.saveApplicationDatas(inputVal)
                      this.validateFieldValue(this.props.field_api_name)
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        let inputVal={}
                      inputVal[this.props.field_api_name]=''
                      this.setState({isDropdownOpenForSelect: !this.state.isDropdownOpenForSelect})
                      this.props.saveApplicationDatas(inputVal)
                      this.validateFieldValue(this.props.field_api_name)
                      }
                    }}
                  >Select</div>
                  {this.SelectOptionsDisplay(this.props.field_api_name)}
                </div>
              )}
          </div>

          { (allDetails['Current_Residential_Status__c'] == "" && 
          this.state.showValidationForResidentialStatus) && 
            <div className='input-group-append-text'>
                <span>
                  <img src="images/tick-mark.svg" alt="tick icon for validation" className='validation-tickmark' />
                </span>
            </div>
          }

          {allDetails['Employment_Stability__c'] == "" &&
            <div className='input-group-append-text'>
                <span>
                  <img src="images/tick-mark.svg" alt="tick icon for validation" className='validation-tickmark' />
                </span>
            </div>
          }


          <p className="error-message">{allDetails[this.props.field_api_name] !== ''  &&
            <span className="app-error-message" style={{ color: 'Red' }}>{allDetails[this.props.field_api_name]}</span>
          }
          </p>
        </div>
      </div>

      <Element fieldItem={this.state.question} />
    </React.Fragment>

  );
}

  render() {
    const allDetails = Object.assign({}, ...this.props.applicationValidation);
    const allDetailData = Object.assign({}, ...this.props.saveApplicationData);
    let disableValue = ''
    if (this.props.editable === false && this.props.prepopulateStatus === true && allDetailData[this.props.field_api_name] != '' && allDetailData[this.props.field_api_name] != null && this.props.prepopulateCondition === true) {
      disableValue = 'nonEditable'
    }
    if (this.state.showDev[this.props.field_api_name] === true) {

        if(this.props.field_api_name === 'frequency'){
          return this.frequencySelectOptions(disableValue,allDetailData,allDetails);
        }
        else if(this.props.field_api_name === 'Loan_Term_Requested__c') {
         return  this.lornTermSelectOptions(disableValue,allDetailData,allDetails);
        }
        else {
         return  this.otherSelectOptions(disableValue,allDetailData,allDetails);
        }
      } else {
      return (
        <React.Fragment>  </React.Fragment>
      );
    }

  }
}


export const mapStateToProps = (state) => {
  return {
    secondSectionSteps: state.dataStore.secondSectionSteps,
    fourSectionSteps: state.dataStore.fourSectionSteps,
    applicationValidation: state.dataStore.applicationValidation,
    showAppValidations: state.dataStore.showAppValidations,
    employmentStatus: state.dataStore.employmentStatus,
    amountRequested: state.dataStore.amountRequested,
    saveApplicationData: state.dataStore.saveApplicationData,
    threashold: state.dataStore.threashold,
    prepopulateStatus: state.dataStore.prepopulateStatus,
    questionValueSave:state.dataStore.questionValueSave,
    isFrequencyExists: state.dataStore.isFrequencyExists,
    // Have used the getApplicationQuestions from data store to get the serviceability details
    getApplicationQuestions: state.dataStore.getApplicationQuestions,
    isMismatchedLoanTerm:state.dataStore.isMismatchedLoanTerm     
  };
};


export const mapDispatchToProps = (dispatch) => {
  return {
    appvalidations: (validations) => {
      return dispatch({
        type: 'APP_VALIDATIONS',
        value: validations
      });
    },
    getEmploynmentstatus: (validations) => {
      return dispatch({
        type: 'EMPLOYNMENTSTATUS',
        value: validations
      });
    },
    saveApplicationDatas: (data) => {
      return dispatch({
        type: 'SAVEDATA',
        value: data
      });
    },
    saveTermData: (data) => {
      return dispatch({
        type: 'SAVETERMDATA',
        value: data
      });
    },
    questionSave:(data)=>{
      return dispatch({
        type: 'QUESTIONSAVE',
        value: data
    });
    },
    secondSectionValidationUpdate: (validations) => {
      return dispatch({
        type: "SECOND_SECTION_STEPS",
        value: validations
      });
    },
    thirdSectionValidationUpdate: (validations) => {
      return dispatch({
        type: "THIRD_SECTION_STEPS",
        value: validations
      });
    },
    fourSectionValidationUpdate: (validations) => {
      return dispatch({
        type: "FOUR_SECTION_STEPS",
        value: validations
      });
    },

  }
}

Select.propTypes = {
  field_api_name: PropTypes.string,
  showAppValidations: PropTypes.string,
  priority: PropTypes.number,
  stage: PropTypes.string,
  field_label: PropTypes.string,
  field_api_validations: PropTypes.array,
  getEmploynmentstatus: PropTypes.func,
  getApplicationQuestions: PropTypes.object,
  saveApplicationDatas: PropTypes.func,
  amountRequested: PropTypes.string,
  applicationValidation: PropTypes.array,
  saveApplicationData: PropTypes.array,
  prepopulateCondition: PropTypes.bool,
  fieldChange: PropTypes.func,
  prepopulateStatus: PropTypes.bool,
  editable: PropTypes.bool,
  field_options: PropTypes.array,
  field_type: PropTypes.string,
  thirdSectionValidationUpdate: PropTypes.func,
  secondSectionValidationUpdate: PropTypes.func,
  fourSectionValidationUpdate: PropTypes.func,
  appvalidations: PropTypes.func,
  saveTermData: PropTypes.func,
  flxQuestionMoreInfo: PropTypes.string,
  questionSave: PropTypes.func,
  isMismatchedLoanTerm:PropTypes.bool
}

export default connect(mapStateToProps, mapDispatchToProps)(Select)

